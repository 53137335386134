.Experience {
    font-family: sans-serif;
    background: white;
    color: white;
    height: 200vh;
  }
  
.react-tabs {
    display: flex;
    color: black;
    background: white;
}
  
.react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 170px;
    height: 100%;
    margin-top: 50px;
    color: black;
    font-weight: 400;
    background: white;
    border-right: 2px solid #96a08b;
}

.react-tabs__tab {
    height: 80px;
    width: 170px;
    list-style: none;
    padding: 20px 20px 10px 10px;
    cursor: pointer;
    color: #bbb;
}

.react-tabs__tab--selected {
    background:#dbe0de;
    border-right: 4px solid #565756;
    color: black;
}

.react-tabs__tab-panel {
    display: none;
    padding-bottom: 10px;
}

.react-tabs__tab-panel--selected {
    display: block;
}

.react-tabs__tab--selected {
    padding-left: 5px;
}

.panel-content {
    /* position: absolute; */
    font-size: larger;
    padding-left: 50px;
}

.company_logo{
    width: 100px;
}

.job-desc {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 0.5rem;
    align-items: center;
    margin-bottom: 0.25rem;
    margin-right: 4rem;
    text-align: left;
    /* border: 1px solid red; */
  }

.job-desc:hover{
    color: #1b1d20;
    background-color:	#F0F0F0;
}

@media all and (max-width: 40em) {
    .react-tabs__tab-list {
        width: 100px;
        height: 100%;
        margin-top: 50px;
    }
    .react-tabs__tab {
        height: 80px;
        width: 100px;
        padding: 10px 10px 10px 5px;
        cursor: pointer;
    }

    .panel-content {
        /* position: absolute; */
        font-size: large;
        padding-left: 30px;
    }

    .job-desc {
        column-gap: 0.5rem;
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
      }
    
}