.contactme {
    /* border: 1px solid blue; */
    justify-content: center;
    text-align: center;
    align-items: center;
  }

.mailto{
    font-size: large;
    font-weight: bold;
    margin-top: 3rem;
    padding: 2rem;
}

.mailto_button{
    border: 3px solid gray;
    border-radius: 10px;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
}

.contactme_social{
    margin-top: 60px;
    display: flex;
    font-size: 35px;
    color: #9a9b9c; 
}

.contactme_social a{
    padding-left: 30px;
    padding-right: 30px;
}

.contactme_social a:hover{
    color: #181818;
}
