.signature{
    width: 250px;
}

@media all and (max-width: 40em) {
    .signature{
        position: absolute;
        top: 20%;
        left: 10%;
        width: 150px;
    }
}