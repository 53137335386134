@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap');

:root{
    --sidebar-flex:1.5;
    --condiv-flex:6;
    --fontsize-p:1.2rem;
    --fontsize-nav:1.6rem;
    --typing-font:3rem;
}
    
*{
    margin:0;
    padding: 0;
}

body{
    font-family: 'Raleway'; 
}

.App
{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.condiv{
    padding: 50px ;
    background-color: #FFFFFF;
    flex:var(--condiv-flex);
    overflow-y: scroll;
  }
  
.home{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.name_header{
  font-size: 60px;
}

h2{
  font-size: 30px;
}

@media all and (max-width: 40em) {
  :root {
    --fontsize-nav:1rem;
    --sidebar-flex:0;
    --typing-font:2rem;
  }
  
  .App
  {
    flex-direction: column;
  }

  .condiv
  {
    padding: 30px ;
  }

  .name_header{
    font-size: 35px;
  }

  h2{
    font-size: 20px;
  }

  nav ul
  {
    display: flex;
  }
  nav ul li
  {
    margin: 10px;
  }
}