.portfolio_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px 30px 0px 30px;
    /* padding: 20px; */
}

.portfolio_img{
    height: 250px;
    width: 300px;
}

.column {
    overflow-wrap: break-word;
    height: 350px;
  }
  
/* .column:hover{
    color: #1b1d20;
    background-color:	#F0F0F0;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
} */

.column h2{
    font-size: 18px;
}

p {
    font-size: 15px;
}

a{
    font-weight: bolder;
    color:#494a49;
    text-decoration: none;
}

@media all and (max-width: 40em) {
    .portfolio_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}