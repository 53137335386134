:root{
    --sidebar-flex:1.5;
    --condiv-flex:6;
    --fontsize-p:1.2rem;
    --fontsize-nav:1.6rem;
    --typing-font:3rem;
}

.profilepic{
    border-radius: 50%;
    width: 200px;
    margin: 30px;
}

.typingeffect{
    font-size: 50px;
    align-items: center;
    margin-bottom: 30px;
  }

.home_social{
    margin-top: 60px;
    display: flex;
    font-size: 35px;
    color: #9a9b9c; 
}

.home_social a{
    padding-left: 30px;
    padding-right: 30px;
}

.home_social a:hover{
    color: #181818;
}

@media all and (max-width: 40em) {
    .profilepic{
        position: absolute;
        border-radius: 50%;
        width: 200px;
        margin: 30px;
        top: 50px;
        right:15%;
        align-items: center;
        justify-content: center;
    }

    .typingeffect{
        position: absolute;
        font-size: 30px;
        align-items: center;
        text-align: center;
        margin-top: 20%;
        margin-bottom: 25px;
    }

    .home_social{
        margin-top: 60px;
        position: absolute;
        top: 70%;
        justify-content: 'center';
        align-items: 'center';
        font-size: 35px;
        color: #9a9b9c; 
    }
}