@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap);
.signature{
    width: 250px;
}

@media all and (max-width: 40em) {
    .signature{
        position: absolute;
        top: 20%;
        left: 10%;
        width: 150px;
    }
}
:root{
    --sidebar-flex:1.5;
    --condiv-flex:6;
    --fontsize-p:1.2rem;
    --fontsize-nav:1.6rem;
    --typing-font:3rem;
}

.profilepic{
    border-radius: 50%;
    width: 200px;
    margin: 30px;
}

.typingeffect{
    font-size: 50px;
    align-items: center;
    margin-bottom: 30px;
  }

.home_social{
    margin-top: 60px;
    display: flex;
    font-size: 35px;
    color: #9a9b9c; 
}

.home_social a{
    padding-left: 30px;
    padding-right: 30px;
}

.home_social a:hover{
    color: #181818;
}

@media all and (max-width: 40em) {
    .profilepic{
        position: absolute;
        border-radius: 50%;
        width: 200px;
        margin: 30px;
        top: 50px;
        right:15%;
        align-items: center;
        justify-content: center;
    }

    .typingeffect{
        position: absolute;
        font-size: 30px;
        align-items: center;
        text-align: center;
        margin-top: 20%;
        margin-bottom: 25px;
    }

    .home_social{
        margin-top: 60px;
        position: absolute;
        top: 70%;
        justify-content: 'center';
        align-items: 'center';
        font-size: 35px;
        color: #9a9b9c; 
    }
}
.Experience {
    font-family: sans-serif;
    background: white;
    color: white;
    height: 200vh;
  }
  
.react-tabs {
    display: flex;
    color: black;
    background: white;
}
  
.react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 170px;
    height: 100%;
    margin-top: 50px;
    color: black;
    font-weight: 400;
    background: white;
    border-right: 2px solid #96a08b;
}

.react-tabs__tab {
    height: 80px;
    width: 170px;
    list-style: none;
    padding: 20px 20px 10px 10px;
    cursor: pointer;
    color: #bbb;
}

.react-tabs__tab--selected {
    background:#dbe0de;
    border-right: 4px solid #565756;
    color: black;
}

.react-tabs__tab-panel {
    display: none;
    padding-bottom: 10px;
}

.react-tabs__tab-panel--selected {
    display: block;
}

.react-tabs__tab--selected {
    padding-left: 5px;
}

.panel-content {
    /* position: absolute; */
    font-size: larger;
    padding-left: 50px;
}

.company_logo{
    width: 100px;
}

.job-desc {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
            column-gap: 0.5rem;
    align-items: center;
    margin-bottom: 0.25rem;
    margin-right: 4rem;
    text-align: left;
    /* border: 1px solid red; */
  }

.job-desc:hover{
    color: #1b1d20;
    background-color:	#F0F0F0;
}

@media all and (max-width: 40em) {
    .react-tabs__tab-list {
        width: 100px;
        height: 100%;
        margin-top: 50px;
    }
    .react-tabs__tab {
        height: 80px;
        width: 100px;
        padding: 10px 10px 10px 5px;
        cursor: pointer;
    }

    .panel-content {
        /* position: absolute; */
        font-size: large;
        padding-left: 30px;
    }

    .job-desc {
        grid-column-gap: 0.5rem;
        -webkit-column-gap: 0.5rem;
                column-gap: 0.5rem;
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
      }
    
}
.widecard{
    border:0px solid #9a9b9c;
    display: flex;
    margin: 0px 0px 30px 100px;
  }
  
  .widecard:hover{
    color: #1b1d20;
    background-color:	#F0F0F0;
  }

  .educationpic{
    width: 80px;
    margin: 10px;
  }

  h1,h2,h3,h4,p{
    margin: 10px;
  }

  @media all and (max-width: 40em) {
    .widecard{
      border:0px solid #9a9b9c;
      display: flex;
      margin: 0px 0px 30px 0px;
    }
  }
.portfolio_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px 30px 0px 30px;
    /* padding: 20px; */
}

.portfolio_img{
    height: 250px;
    width: 300px;
}

.column {
    overflow-wrap: break-word;
    height: 350px;
  }
  
/* .column:hover{
    color: #1b1d20;
    background-color:	#F0F0F0;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
} */

.column h2{
    font-size: 18px;
}

p {
    font-size: 15px;
}

a{
    font-weight: bolder;
    color:#494a49;
    text-decoration: none;
}

@media all and (max-width: 40em) {
    .portfolio_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}
.contactme {
    /* border: 1px solid blue; */
    justify-content: center;
    text-align: center;
    align-items: center;
  }

.mailto{
    font-size: large;
    font-weight: bold;
    margin-top: 3rem;
    padding: 2rem;
}

.mailto_button{
    border: 3px solid gray;
    border-radius: 10px;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
}

.contactme_social{
    margin-top: 60px;
    display: flex;
    font-size: 35px;
    color: #9a9b9c; 
}

.contactme_social a{
    padding-left: 30px;
    padding-right: 30px;
}

.contactme_social a:hover{
    color: #181818;
}

:root{
    --sidebar-flex:1.5;
    --condiv-flex:6;
    --fontsize-p:1.2rem;
    --fontsize-nav:1.6rem;
    --typing-font:3rem;
}
    
*{
    margin:0;
    padding: 0;
}

body{
    font-family: 'Raleway'; 
}

.App
{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.condiv{
    padding: 50px ;
    background-color: #FFFFFF;
    flex:6;
    flex:var(--condiv-flex);
    overflow-y: scroll;
  }
  
.home{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.name_header{
  font-size: 60px;
}

h2{
  font-size: 30px;
}

@media all and (max-width: 40em) {
  :root {
    --fontsize-nav:1rem;
    --sidebar-flex:0;
    --typing-font:2rem;
  }
  
  .App
  {
    flex-direction: column;
  }

  .condiv
  {
    padding: 30px ;
  }

  .name_header{
    font-size: 35px;
  }

  h2{
    font-size: 20px;
  }

  nav ul
  {
    display: flex;
  }
  nav ul li
  {
    margin: 10px;
  }
}
