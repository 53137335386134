.widecard{
    border:0px solid #9a9b9c;
    display: flex;
    margin: 0px 0px 30px 100px;
  }
  
  .widecard:hover{
    color: #1b1d20;
    background-color:	#F0F0F0;
  }

  .educationpic{
    width: 80px;
    margin: 10px;
  }

  h1,h2,h3,h4,p{
    margin: 10px;
  }

  @media all and (max-width: 40em) {
    .widecard{
      border:0px solid #9a9b9c;
      display: flex;
      margin: 0px 0px 30px 0px;
    }
  }